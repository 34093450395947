<template>
  <div v-if="group.event">
    <h4>Today's Event Record ({{ $moment().format('l') }})</h4>
    <h5>{{ group.event.title }} Participants ({{ group.name }}: {{ group.participants.length }})</h5>
    <b-form class="event-group-confirm" v-if="group">
      <b-row>
        <b-col cols="12" lg="6">
          <div class="text-right mb-3">
            <router-link v-b-tooltip.hover title="Message all primary contacts" :to="{ name: 'Compose', params: { pgid: this.$route.params.egid } }"><i class="fa fa-envelope"></i> Message Group</router-link>
          </div>
          <p class="border border-success text-success p-3">
            Below, check boxes next to participants who are present and accounted for or select an option from the associated drop-down.
          </p>
          <div
            class="p-2 mb-4 font-weight-bold cursor-pointer"
            :class="(presentAll ? 'bg-warning' : 'bg-success')"
            @click="togglePresentAll()"
          >
            <span style="display:inline-block;width:1.2rem;"><i
              class="fa"
              :class="(!presentAll ? 'fa-square-o' : 'fa-check-square-o')"
            ></i></span>
            {{ !presentAll ? 'Check' : 'Uncheck' }} All Participants
          </div>
          <hr />
          <template v-for="(participant, index) in group.participants">
            <div :key="'participant-container-' + index">
            <b-dropdown
              @click="togglePresent(participant.id)"
              :variant="((present.findIndex(uid => uid === participant.id) > -1) ? 'success' : '')"
              :key="'participant-dd-' + index"
              class="mb-1"
              split
              block
              right
            >
              <template v-slot:button-content>
                <span style="display:inline-block;width:1.2rem;"><i
                  class="fa"
                  :class="((present.findIndex(pid => pid === participant.id) > -1) ? 'fa-check-square-o' : 'fa-square-o')"
                ></i></span>
                {{ participant.fullName }}
              </template>
              <!-- b-dropdown-item @click="toggleNotes(index)"><i class="fa fa-pencil"></i> Edit Notes</b-dropdown-item -->
              <b-dropdown-item @click="onViewParticipant(participant.id)"><i class="fa fa-eye"></i> View</b-dropdown-item>
            </b-dropdown>

            <!-- div class="py-2 clearfix" :key="'participant-' + index">
              <b-btn size="sm" :variant="(participant.notes ? 'warning' : '')" class="float-right m-1" @click="toggleNotes(index)">...</b-btn>
              <div
                class="cursor-pointer p-2 font-weight-bold"
                :class="((present.findIndex(uid => uid === participant.id) > -1) ? 'bg-success' : 'bg-white')"
                @click="togglePresent(participant.id)"
              >
                <span style="display:inline-block;width:1.2rem;"><i
                  class="fa"
                  :class="((present.findIndex(uid => uid === participant.id) > -1) ? 'fa-check-square-o' : 'fa-square-o')"
                ></i></span>
                {{ participant.fullName }}
              </div>
            </div -->
            <b-form-textarea
              class="mb-1"
              :hidden="!participantNotesShow[index]"
              :key="'participant-notes-' + index"
              v-model="participant.notes"
            ></b-form-textarea>
            </div>
          </template>
        </b-col>
        <b-col>
          <b-form-textarea
            v-model="notes"
            size="lg"
            rows="3"
            max-rows="8"
            class="mt-4"
            placeholder="Notes (optional)"
          ></b-form-textarea>
          <b-btn variant="success" size="lg" class="mt-4" @click="saveRecord" block>Update</b-btn>
        </b-col>
      </b-row>
    </b-form>
    <participant-view-modal v-if="viewParticipant" :pid="viewParticipant" @view-modal-closed="viewParticipant=null"></participant-view-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import participantViewModal from '@/components/ParticipantViewModal'

export default {
  name: 'EventGroup',
  components: {
    participantViewModal
  },
  data () {
    return {
      id: null,
      present: [],
      presentAll: false,
      group: {},
      participantNotesShow: [],
      viewParticipant: null,
      notes: null
    }
  },
  computed: {
    ...mapGetters([
      'eventGroups',
      'eventRecords'
    ])
  },
  async mounted () {
    this.date = this.$moment().format('YYYY-MM-DD')
    await this.getEventGroups({
      id: this.$route.params.egid
    })
    this.group = this.eventGroups[0]
    this.group.participants.sort((a, b) => {
      var nameA = a.fullName.toUpperCase()
      var nameB = b.fullName.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    for (let k in this.group.participants) {
      this.participantNotesShow.push(false)
    }
    await this.getEventRecords({
      participantsGroup: this.$route.params.egid,
      'date[after]': this.date,
      'date[before]': this.date
    })
    if (this.eventRecords[0]) {
      this.setGroupRecord(this.eventRecords[0])
    }
  },
  methods: {
    ...mapActions([
      'getEventGroups',
      'getEventRecords',
      'postEventRecord',
      'putEventRecord'
    ]),
    togglePresent (id) {
      if (!id || isNaN(id)) return
      let i = this.present.findIndex(uid => uid === id)
      if (i === -1) {
        this.present.push(id)
      } else {
        this.present.splice(i, 1)
        this.presentAll = false
      }
      if (this.present.length === this.group.participants.length) {
        this.presentAll = true
      }
    },
    togglePresentAll () {
      this.present = []
      this.presentAll = !this.presentAll
      if (this.presentAll) {
        this.present = [ ...this.group.participants ].map(p => p.id)
      }
    },
    toggleNotes (index) {
      this.participantNotesShow[index] = !this.participantNotesShow[index]
    },
    onViewParticipant (pid) {
      this.viewParticipant = pid
    },
    setGroupRecord (data) {
      this.id = data.id
      let participants = [ ...this.group.participants ]
      this.present = participants.filter(p => (data.absent.indexOf(p['@id']) === -1)).map(p => p.id)
      this.notes = data.notes
      if (this.present.length === this.group.participants.length) {
        this.presentAll = true
      }
    },
    async saveRecord () {
      let participants = [ ...this.group.participants ]
      let absent = participants.filter(p => (this.present.indexOf(p.id) === -1)).map(p => p['@id'])
      let record = {
        date: this.$moment().format('YYYY-MM-DD'),
        event: this.group.event['@id'],
        participantsGroup: this.group['@id'],
        coach: this.group.coach,
        absent: absent,
        notes: this.notes
      }
      try {
        if (!this.id) {
          await this.postEventRecord(record).then(resp => {
            this.id = resp.id
          });
        } else {
          record.id = this.id
          await this.putEventRecord(record)
        }
      } catch (e) {

      }
      this.showSuccess()
    }
  },
}
</script>

<style>
.event-group-confirm .list-group-item {
  font-size: 1.2rem;
  cursor:pointer;
  margin-bottom:7px;
}
</style>