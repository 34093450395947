<template>
	<b-modal v-if="pid" v-model="show" ref="viewModal" title="Participant Details" size="lg" @hide="$emit('view-modal-closed')" ok-only lazy>
	  <b-container fluid>
	   <participant-details :pid="pid" @close="close()"></participant-details>
	  </b-container>
	  <div slot="modal-footer" class="w-100">
       <b-button-group class="float-right">
	       <b-btn variant="success" @click="show=false">
	         Close
	       </b-btn>
	       <!-- b-btn variant="warning" @click="show=false" :to="{ name: 'ParticipantForm', params: { uid: pid }}">
	         Edit
	       </b-btn -->
       </b-button-group>
     </div>
	</b-modal>
</template>

<script>
import ParticipantDetails from '@/views/participants/ParticipantDetails'

export default {
	name: 'participantViewModal',
	components: {
		ParticipantDetails
	},
	props: {
		pid: {
			type: Number
		}
	},
	data () {
		return {
			show: true,
			participant: {}
		}
	},
	methods: {
		close () {
			this.$refs.viewModal.hide()
		}
	},
	watch: {
		pid (val) {
			if (! val) return
		}
	}
}
</script>